import React from "react";
import Head from "next/head";
import { useRouter } from "next/dist/client/router";
import serverSideProps from "../util/serverSideProps";

import getInitialLocale from "../locale/getInitialLocale";
import { destructureLocale } from "../locale/helpers";

import fetch from "isomorphic-unfetch";

const homePage = ({ locales }) => {
  const router = useRouter();
  const [curLocation, setCurLocation] = React.useState();

  React.useEffect(() => {
    const dataLocation = async () => {
      try {
        const data = await fetch("https://ipapi.co/json/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const location = await data.json();
        setCurLocation(location);
      } catch (error) {}
    };
    dataLocation();
  }, []);

  React.useEffect(() => {
    if (curLocation) {
    //Wrong Geolocalization - #478: devo modificare la country di provenienza con quella dichiarata in Directus
    //Updated 26/05/2022  
      if ('ES' === curLocation.country_code){
        curLocation.country_code = 'SP'
      }
      if ('GB' === curLocation.country_code){
        curLocation.country_code = 'UK'
      }
      const country = locales.find(
        (el) => el.code === curLocation.country_code
      );
      if (country) {
        //Wrong Geolocalization - #478: devo modificare la lingua di provenienza con quella dichiarata in Directus
        let language;
        if (country.code == 'MX'){
            language = country.languages.find((el) =>
            el.code.includes('SP')
          );
        }
        else if (country.code == 'UK'){
          language = country.languages.find((el) =>
            el.code.includes('US')
          );
        }
        else{
          language = country.languages.find((el) =>
            el.code.includes(country.code)
          );
        }
        router.replace(
          "/[country]/[lang]",
          `/${country.code}/${language.code}`
        );
      } else {
        switch (curLocation.continent_code) {
           case 'EU': {
             router.replace(
               "/[country]/[lang]",
               `/OU/en_US`
             );
             break;
           }
          case 'AS': {
            router.replace(
              "/[country]/[lang]",
              `/ROA/en_US`
            );
            break;
          }
          case 'SA': {
            router.replace(
              "/[country]/[lang]",
              `/HA/en_US`
            );
            break;
          }
        }
      }
    } else {
      const { country, language } = destructureLocale(
        getInitialLocale(locales)
      );
      router.replace("/[country]/[lang]", `/${country}/${language}`);
    }
  }, [curLocation]);

  return (
    <Head>
      <meta name="robots" content="noindex, nofollow" />
    </Head>
  );
};

export const getServerSideProps = async (ctx) => {
  const { locale, locales, regions } = await serverSideProps(ctx);
  try {
    if (!locale) {
      throw new Error("Locale is not valid");
    }

    return {
      props: {
        locale,
        locales,
        regions,
      },
    };
  } catch (e) {
    return {
      props: {
        locale,
        locales,
        regions,
      },
    };
  }
};

homePage.getLayout = (page) => <>{page}</>;

export default homePage;
