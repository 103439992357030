import { defaultLocale } from './config';
import {
  isCountry,
  isLanguage,
  isLocale,
  convertLanguageToStandard,
  getCountryFromLanguage,
  mergeLocale,
} from './helpers';

const findLocale = locales => {
  // preference from the previous session
  // localStorage stores the locale in merged form
  const localSetting = localStorage.getItem('locale');

  if (isLocale(localSetting, locales)) {
    return localSetting;
  }

  // the language setting of the browser
  let browserLanguage = navigator.language;
  if (browserLanguage.split('-').length !== 2) {
    browserLanguage = convertLanguageToStandard(browserLanguage);
  } else {
    browserLanguage = browserLanguage.replace('-', '_');
    browserLanguage = browserLanguage.replace(/^([a-z]{2})_GB$/, '$1_UK');
  }

  if (isLanguage(browserLanguage, locales)) {
    const browserCountry = getCountryFromLanguage(locales, browserLanguage);
    if (isCountry(browserCountry?.code, locales)) {
      return mergeLocale(browserCountry.code, browserLanguage);
    }
  }
  return false;
};

export const isLocaleFound = locales => {
  const foundLocale = findLocale(locales);
  return !!foundLocale;
};

const getInitialLocale = locales => {
  const foundLocale = findLocale(locales);

  if (foundLocale) {
    return foundLocale;
  }

  return defaultLocale;
};

export default getInitialLocale;
