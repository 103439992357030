export const defaultLocale = 'IT:en_US';

export const locales = {
  eu: ['en_GB', 'it_IT', 'de_DE', 'bg_BG', 'fr_FR'],
  came: ['ar_SA'],
  na: ['en_US', 'es_MX'],
};

export const languageNames = {
  en_GB: 'English',
  it_IT: 'Italian',
  de_DE: 'German',
  bg_BG: 'Bulgarian',
  fr_FR: 'French',
  ar_SA: 'Arab',
  en_US: 'English',
  es_MX: 'Spanish',
};
